@import "./../../styles/material-colors.scss";

 
.login-page{
    .form-login {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    
        .form-control {
            position: relative;
            box-sizing: border-box;
            height: auto;
            padding: 10px;
            font-size: 16px;
    
            &:focus {
                z-index: 2;
            }
        }
    
        .username {
            margin-bottom: -1px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    
        .password {
            margin-bottom: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }  
} 